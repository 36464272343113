var firebaseConfig = {
    apiKey: "AIzaSyDvwAmeQozJ_h4CVcY7qfxnIb6Xgx78_TI",
    authDomain: "brewermemepedia.firebaseapp.com",
    databaseURL: "https://brewermemepedia.firebaseio.com",
    projectId: "brewermemepedia",
    storageBucket: "brewermemepedia.appspot.com",
    messagingSenderId: "495264437338",
    appId: "1:495264437338:web:b371fc458502134566b463",
    measurementId: "G-R109RG10MC"
  };

export default firebaseConfig;